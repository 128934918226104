<template>
  <!--begin::RegisterOK-->
  <div class="login-form text-center">
    <h2 class="text-success">
      <inline-svg class="mr-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("REGISTER.FINISHED_OK_1") }}
    </h2>

    <p>
      {{ $t("REGISTER.FINISHED_OK_2") }}
    </p>

    <button
      class="btn btn-pill btn-white button-form button-form-primary my-12"
      @click="goToLogin"
    >
      {{ $t("REGISTER.ALREADY_REGISTERED_2") }}
    </button>
  </div>
  <!--end::RegisterOK-->
</template>

<script>
export default {
  name: "RegisterOK",

  mounted() {
    // Set parent layout content
    this.$emit(
      "layout-login-aside-background",
      process.env.BASE_URL + "media/bg/imagen-bienvenido.jpg"
    );
    this.$emit("layout-login-aside-title", this.$i18n.t("REGISTER.TITLE"));
    this.$emit("layout-login-aside-description", "");
  },

  methods: {
    goToLogin() {
      this.$router.push("/login");
    }
  }
};
</script>
